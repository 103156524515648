@import url('https://fonts.googleapis.com/css2?family=Krona+One&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.home {
    background-image: url('../src/assets/images/goodevil.webp');
}

.temple {
    background-image: url('../src/assets/images/temple.webp');
}

.world {
    background-image: url('../src/assets/images/world.webp');
    background-position: center;
    background-size: 105%;
    transition: all 0.2s linear !important;
}

.games {
    background-image: url('../src/assets/images/games.webp');
    background-position: bottom;
}

.worldswebuild {
    background-image: url('../src/assets/images/worldswebuild.png');
}

* {
    overflow-x: hidden;
}

#root {
    width: 100%;
}

.input-border {
    width: 100%;
    height: 6.9vh;
    margin-left: 1rem;
    margin-right: 1rem;
    position: relative;
    margin-bottom: 1vh;
    border: none;
    border-bottom: 0.7px solid #716C6C;
    overflow-y: hidden;
}

.text {
    width: 100%;
    height: 100%;
    padding: 0 10px;
    border: none;
    border-bottom: 0.7px solid #716C6C;
    background: none;
    font-size: 16px;
    color: white;
}

.border {
    position: absolute;
    bottom: -1px;
    left: 0px;
    width: 0;
    height: 2px;
    background: #716C6C;
    transition: .5s;
}

.text:focus~.border,
.text:valid~.border {
    width: 100%;
    color: #716C6C;
}

.text:hover {
    border-bottom: 0.7px #716C6C;
}

.label {
    position: absolute;
    top: 4px;
    left: 10px;
    color: #716C6C;
    pointer-events: none;
    font-size: 17px;
    transition: .5s;
}


.text:focus~.label,
.text:valid~.label {
    top: -4px;
    left: 10px;
    color: #716C6C;
    font-size: 17px;
}

* {
    outline: none;
}

.cont_ {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: auto;
    scroll-snap-type: y mandatory;
}

.cont_ .section {
    position: relative;
    width: 100%;
    height: 100%;
    scroll-snap-align: end;
    background-blend-mode: multiply;

}

.cont_ .section .content {
    position: absolute;

}

.cont_::-webkit-scrollbar {
    display: none;
}

.cont_ .section:nth-child(1) {
    background: #efefed;
    scroll-snap-stop: always;
    background-attachment: fixed;
    background-size: cover;
}

.cont_ .section:nth-child(2) {
    background: #efefed;
    scroll-snap-stop: always;
    background-attachment: fixed;
    background-size: cover;
}

.child {
    background-color: white;
}

.parent:hover .child {
    background-color: #BA62FF
}

.clicked {
    color: #BA62FF;
}

.bg-violet {
    background-color: #BA62FF
}


.animate-scale {
    animation: zoomInOut 60s linear infinite;
    transition: transform 60s ease-in-out;
}

@keyframes zoomInOut {
    0% {
        background-size: 108%;
        background-position: center;
    }

    50% {
        background-size: 125%;
        background-position: center;
    }

    100% {
        background-size: 108%;
        background-position: center;
    }
}

.pulse-animation {
    animation: pulse 10s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
    50% {
        opacity: .7;
    }
}

.icon_tray {
    animation: tray 10s linear;
}
